import { addDays, format } from 'date-fns';

import { Appointment } from 'types';

/**
 * We cannot depend on the appointments the api returns for the next x consecutive days
 * Therefore, we are creating a new object with the next x consecutive days based on
 * the device size and how many days to show.
 * @returns Array<{date: string, appointments: Appointment[]}>
 */
export const buildAppointmentsByDateCollection = (startDate: Date, numberOfDays: number, unsortedAppointments: Appointment[]) => {
    // Build array of dates in the format MM/dd/yyyy ['01/01/2021', '01/02/2021', ...']
    const dateArray = new Array(numberOfDays)
        .fill(null)
        .map((_, index) => format(addDays(new Date(startDate), index), 'MM/dd/yyyy'));

    const sortedAppointments: (Appointment | null)[][] = new Array(numberOfDays).fill([]);

    let minimumAppointmentCount = 0;

    unsortedAppointments.forEach((appointment) => {
        const dateIndex = dateArray.indexOf(appointment.date);
        if (dateIndex !== -1) {
            const newAppointmentCollection = [...(sortedAppointments[dateIndex] || []), appointment];
            sortedAppointments[dateIndex] = newAppointmentCollection;
            minimumAppointmentCount = Math.max(minimumAppointmentCount, newAppointmentCollection.length);
        }
    });

    return sortedAppointments.map((appointments, index) => {
        while (appointments.length < minimumAppointmentCount) appointments.push(null);

        return { date: dateArray[index], appointments };
    });
};
