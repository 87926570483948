import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';

import { aceApi } from 'api/ace';
import type { Market } from 'types';
import { useAppOptions } from './use-app-options';

const useMarkets = (): UseQueryResult<Market[]> => {
    const appOptions = useAppOptions();

    return useQuery({
        queryKey: ['markets', appOptions.practice],
        queryFn: async () => {
            const response = await aceApi.get<{ market_configurations: Market[] }>('/scheduling/config/markets', {
                params: { web_scheduling_enabled: true, scheduling_source: appOptions.practice },
            });

            return response.data.market_configurations;
        },
        enabled: appOptions.enabledForScheduling,
    });
};

export { useMarkets };
