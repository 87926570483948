import { Reason } from 'types';

const REASONS_DEFAULT = [
    {
        is_in_person: true,
        name: 'Adult Problem/Sick Visit',
    },
    {
        is_in_person: true,
        name: 'Problem/Sick Visit',
    },
    {
        is_in_person: true,
        name: 'New Patient',
    },
    {
        is_in_person: true,
        name: 'Pediatric Sick Visit - 5-17 years',
    },
];

const REASONS_DEFAULT_EXISTING_PATIENTS = [
    {
        is_in_person: true,
        name: 'Adult Problem/Sick Visit',
    },
    {
        is_in_person: true,
        name: 'Problem/Sick Visit',
    },
    {
        is_in_person: true,
        name: 'Consult/Problem',
    },
    {
        is_in_person: true,
        name: 'Problem',
    },
    {
        is_in_person: true,
        name: 'Pediatric Sick Visit - 5-17 years',
    },
];

/**
 * Get a reason to auto select by default based on the ordering of `REASONS_DEFAULT`.
 * @param reasons
 * @param isExistingPatient
 * @return matched reason from reasons based on the ordering of REASON_DEFAULT or reasons[0] if no match found.
 */

const getDefaultReason = (reasons: Reason[], isExistingPatient: boolean | undefined): Reason => {
    const defaultReasons = isExistingPatient ? REASONS_DEFAULT_EXISTING_PATIENTS : REASONS_DEFAULT;

    const matchedReason = defaultReasons.reduce<Reason | null>((acc, defaultReason) => {
        const match = reasons.find(
            (reason) =>
                reason.is_in_person === defaultReason.is_in_person &&
                reason.name.toLowerCase() === defaultReason.name.toLowerCase()
        );

        if (match && !acc) {
            return match;
        }

        return acc;
    }, null);

    return matchedReason ?? reasons[0];
};

export { REASONS_DEFAULT, REASONS_DEFAULT_EXISTING_PATIENTS, getDefaultReason };
