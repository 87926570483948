import { parseISO } from 'date-fns';
import * as Yup from 'yup';

const digitsOnlyRegex = /^[0-9]*$/;
const booleanRegex = /^true|false$/;

const appConfigDatadogSchema = Yup.object().shape({
    rumApplicationId: Yup.string(),
    rumClientToken: Yup.string(),
    logsClientToken: Yup.string(),
    enableSessionRecording: Yup.boolean(),
});

const citymdAppConfig = Yup.object().required().shape({
    datadog: appConfigDatadogSchema,
});

const appConfigSchema = Yup.object()
    .required()
    .shape({
        aceApiBaseUrl: Yup.string().required(),
        hubspotApiBaseUrl: Yup.string().required(),
        hubspotProvidersTableId: Yup.string().required(),
        hubspotPortalId: Yup.string().required(),
        countlyAppKey: Yup.string(),
        countlyUrl: Yup.string(),
        environment: Yup.mixed().oneOf(['test', 'uat', 'production']),
        datadog: appConfigDatadogSchema,
        citymd: citymdAppConfig,
    });

// Validate the booking params passed to the scheduler from the URL and the storage
const bookingParamsSchema = Yup.object()
    .required()
    .shape({
        marketKey: Yup.string().matches(/^[a-z]{3}-[a-z]{2}-[a-z]{3}$/),
        date: Yup.date().transform((value: string | undefined) => (value && typeof value === 'string' ? parseISO(value) : value)),
        departmentId: Yup.number().transform((value: string | undefined) =>
            typeof value === 'string' && digitsOnlyRegex.test(value) ? Number(value) : value
        ),
        appointmentId: Yup.number().transform((value: string | undefined) =>
            typeof value === 'string' && digitsOnlyRegex.test(value) ? Number(value) : value
        ),
        providerId: Yup.number().transform((value: string | undefined) =>
            typeof value === 'string' && digitsOnlyRegex.test(value) ? Number(value) : value
        ),
        reasonId: Yup.number().transform((value: string | undefined) =>
            typeof value === 'string' && digitsOnlyRegex.test(value) ? Number(value) : value
        ),
        isExistingPatient: Yup.bool().transform((value: string | undefined) =>
            typeof value === 'string' && booleanRegex.test(value) ? value === 'true' : value
        ),
    });

const mainSchedulerConfigSchema = Yup.object()
    .required()
    .shape({
        // If using the main scheduler, scheduling is always enabled
        enabledForScheduling: Yup.bool().default(true),
        rootElementId: Yup.string().required(),
        scrollToViewOnMount: Yup.bool(),
        scrollTopOffsetPx: Yup.number(),
        practice: Yup.mixed<'villagefamilypractice'>().oneOf(['villagefamilypractice']).default('villagefamilypractice'),
    });

const providerWidgetSchedulerConfigSchema = Yup.object()
    .required()
    .shape({
        type: Yup.mixed<'providerWidget'>().oneOf(['providerWidget']).required(),
        practice: Yup.mixed<'villagefamilypractice'>().oneOf(['villagefamilypractice']).default('villagefamilypractice'),
        enabledForScheduling: Yup.bool().required(),
        rootElementId: Yup.string().required(),
        defaultDepartmentId: Yup.number().required(),
        marketKey: Yup.string().required(),
        departmentsList: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        // In HubDB sometimes the location is not set for the provider, so we need to allow null
                        id: Yup.string().required().nullable(),
                        path: Yup.string().required(),
                    })
                    .required()
            )
            .required(),
        providerNpi: Yup.number(),
        date: Yup.date(),
        reasonId: Yup.number(),
        isClinicClosedForToday: Yup.bool(),
    });

const locationWidgetSchedulerConfigSchema = Yup.object()
    .required()
    .shape({
        type: Yup.mixed<'locationWidget'>().oneOf(['locationWidget']).required(),
        practice: Yup.mixed<'villagefamilypractice'>().oneOf(['villagefamilypractice']).default('villagefamilypractice'),
        enabledForScheduling: Yup.bool().required(),
        rootElementId: Yup.string().required(),
        marketKey: Yup.string().required(),
        departmentId: Yup.number().required(),
        date: Yup.date(),
        reasonId: Yup.number(),
        isClinicClosedForToday: Yup.bool(),
        providerId: Yup.number(),
    });

const citymdSchedulerConfigSchema = Yup.object()
    .required()
    .shape({
        enabledForScheduling: Yup.bool().required(),
        departmentAddress: Yup.object()
            .shape({
                address1: Yup.string(),
                address2: Yup.string(),
                city: Yup.string(),
                state: Yup.string(),
                zip: Yup.string(),
            })
            .required(),
        departmentId: Yup.number().required(),
        departmentName: Yup.string().required(),
        departmentPhone: Yup.string().required(),
        directionsLink: Yup.string().required(),
        imageUrl: Yup.string().required(),
        isOpen: Yup.boolean(),
        locationDetailLink: Yup.string().required(),
        rootElementId: Yup.string().required(),
        page: Yup.mixed<'location-list' | 'location-detail'>().oneOf(['location-list', 'location-detail']).required(),
        practice: Yup.mixed<'summitcitypractice'>().oneOf(['summitcitypractice']).default('summitcitypractice'),
        preRegisterLink: Yup.string(),
        type: Yup.mixed<'citymd'>().oneOf(['citymd']).required(),
        waitTime: Yup.number().nullable(),
    });

export {
    appConfigSchema,
    mainSchedulerConfigSchema,
    providerWidgetSchedulerConfigSchema,
    locationWidgetSchedulerConfigSchema,
    citymdSchedulerConfigSchema,
    bookingParamsSchema,
};
